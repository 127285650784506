.Burn {
  position: relative;
  //flex: 1
  color: white;

  input {
    color: inherit; }

  .fire {
    position: fixed;
    z-index: -5;
    bottom: 0;
    right: 0;
    //left: 0
    background-color: black;
    height: 100%;
    width: calc(100% - 400px); }

  @media (max-width: 400px) {
    .fire {
      width: 100%;
      bottom: -30%; } } }
