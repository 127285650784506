@charset "UTF-8";
.About .description {
  padding: 50px;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  line-height: 1.5; }
  .About .description h2 {
    font-size: 24px !important;
    line-height: 1; }
  .About .description > * {
    min-width: 300px;
    max-width: 500px;
    margin: 0 25px;
    margin-bottom: 50px; }
  .About .description ul {
    list-style-type: none; }
    .About .description ul li {
      position: relative; }
      .About .description ul li:before {
        content: "✓";
        position: absolute;
        left: -20px; }
  .About .description a {
    color: inherit;
    text-decoration: none;
    background-color: orange;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block; }
    .About .description a:hover {
      animation: vibrate-1 0.3s linear infinite both; }
    .About .description a:visited {
      color: inherit; }
  .About .description .site-footer hr {
    margin: 12px 0;
    margin-right: -500px; }
    @media (max-width: 400px) {
      .About .description .site-footer hr {
        margin-right: 0; } }
  .About .description .site-footer .links, .About .description .site-footer .byline {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .About .description .site-footer .links a, .About .description .site-footer .byline a {
      margin-right: 12px; }
      .About .description .site-footer .links a:last-child, .About .description .site-footer .byline a:last-child {
        margin: 0; }
  .About .description .site-footer .personal a {
    background-color: springgreen; }
  .About .description .spiel {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
