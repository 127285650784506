.App, #root, body, html {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  @media (max-width: 400px) {
    overflow: unset; } }

.App {
  @media (max-width: 400px) {
    header {
      width: 100%;
      top: 0;
      position: fixed; }

    nav {
      position: fixed;
      top: 25vw; }
    padding-top: 35vw; } }

header {
  display: block;
  text-align: center;
  z-index: 2;
  flex-shrink: 0;

  transform-origin: top left;
  transform: rotate(-8deg);

  position: relative;

  h2 {
    position: absolute;
    bottom: 0;
    font-size: 3vw;
    font-weight: 700;
    font-style: italic;
    background-color: white;
    border: 1.5vw double black;
    right: -100px;
    left: -100px; }

  h1 {
    font-family: Monoton, sans-serif;
    font-weight: normal;
    font-size: 15vw;
    background-color: springgreen;
    color: green;
    border: 1.5vw double black;
    border-radius: 10px;
    margin: 0;
    width: 80%; } }

nav {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-end;
  transform: rotate(5deg);
  transform-origin: top right;
  background-color: orange;
  width: 100%;
  margin-left: -50%;
  padding: 20px 45%;

  flex-wrap: wrap;
  border: 1.5vw double black;
  border-radius: 10px;
  z-index: 1;
  margin-bottom: -50px;
  a {
    background-color: white;
    cursor: pointer;

    display: block;
    text-decoration: none;
    border: 6px double black;
    padding: 10px 30px;
    border-radius: 5px;
    margin-left: 2.5vw;
    color: black;
    font-weight: bold;
    .icon {
      display: none; }
    &.active {
      border-color: orange;
      color: orange;
      background-color: black; }
    &:hover:not(.active) {
      animation: vibrate-1 0.3s linear infinite both; } }

  @media (max-width: 400px) {
    padding-top: 5px;
    padding-bottom: 5px;
    a {
      font-size: 25px;
      &.about {
        display: none; }
      padding: 5px 10px;

      .icon {
        display: inline; }
      .text {
        display: none; } } } }
@keyframes vibrate-1 {
  0% {
    transform: translate(0); }

  20% {
    transform: translate(-2px, 2px) rotate(2deg); }

  40% {
    transform: translate(-2px, -2px);
    background-color: orange;
    color: white; }

  60% {
    transform: translate(2px, 2px) rotate(-2deg); }

  80% {
    transform: translate(2px, -2px); }

  100% {
    transform: translate(0); } }


