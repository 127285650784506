.Shucks {
  display: inline-flex;
  align-items: center;
  font-size: 25px;
  &.native {
    &, .shuck-symbol {
      font-size: inherit; } }

  .shuck-symbol {
    font-size: 50px;
    font-family: Monoton, sans-serif; } }
