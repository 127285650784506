.Account {
  display: flex;
  flex: 1;
  flex-direction: row;

  .sidebar {
    z-index: 0;
    min-width: 400px;
    position: relative;
    margin-right: 50px;
    color: orange;
    display: flex;
    flex-direction: column;

    .balance {
      padding: 0 20px;
      margin-top: -10%;
      .shucks {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .econ {
          text-align: right; } } }
    .transactions {
      .icon {
        display: none; }

      h3 {
        padding: 0 20px; }

      overflow-y: auto;
      overflow-x: visible;
      flex: 1;
      flex-basis: 0;

      table {
        width: 100%;
        border-collapse: collapse;
        td {
          padding: 0 20px;
          border-bottom: 3px solid black; }

        tr:nth-child(2n) {
          background: orange;
          color: purple; } } }


    &:before {
      content: "";
      border-right: 15px double black;
      background-color: purple;
      position: absolute;
      top: -1000px;
      right: -15px;
      bottom: 0;
      z-index: -1;
      left: 0; } }


  .About {
    overflow: auto; }

  .info {
    margin-top: -3%;
    flex: 1;

    display: flex;
    flex-direction: column;
    > * {
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;

      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      > * {
        padding: 20px; } }

    h2 {
      font-size: 100px;
      margin: 0;
      //margin-top: -50px
 } }      //margin-bottom: 100px
  @media (max-width: 400px) {
    flex-direction: column;
    width: 100%;
    .sidebar {
      transform: rotate(-1deg);
      position: fixed;
      top: 30vw;
      //bottom: 120vw
      height: auto;

      width: 100%;
      &:before {
        display: none; }
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      height: 70px;
      margin: 0;
      min-width: unset;
      outline: 5px solid black;
      background-color: purple;
      padding-top: 50px;
      h3 {
        display: none; }
      .transactions {
        table {
          td:nth-child(2) {
            padding: 0; } }
        overflow: unset;
        .icon {
          display: inline; }
        .text {
          display: none; } } }

    .info {
      margin-top: 100px;
      > * {
        overflow: unset; }
      h2 {
        margin: 0;
        font-size: 30px;
        background-color: black;
        color: orange; }
      input {
        display: block;
        width: 100%; } } } }
