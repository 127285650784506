@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,400i,700,700i|Monoton);
body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Libre Franklin', sans-serif;
  margin: 0;
  background-color: rgba(222, 229, 219, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='%23ffffff' fill-opacity='0.4' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E"); }

form {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  form .field {
    display: flex;
    flex-direction: row;
    flex-shrink: 0; }

input {
  background: none;
  border: 6px double transparent;
  border-bottom-color: purple;
  font-size: 30px;
  display: block;
  width: 100%; }
  input:focus {
    outline: none;
    background-color: orange;
    border-color: black; }

button {
  background: white;
  cursor: pointer;
  display: block;
  text-decoration: none;
  border: 6px double black;
  padding: 10px 30px;
  border-radius: 5px;
  margin-left: 2.5vw;
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
  margin-top: 20px;
  display: block;
  width: 100%; }
  button:hover:not(.active) {
    -webkit-animation: vibrate-1 0.3s linear infinite both;
            animation: vibrate-1 0.3s linear infinite both; }

hr {
  border: none;
  border-bottom: 6px double black; }

@charset "UTF-8";
.About .description {
  padding: 50px;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  line-height: 1.5; }
  .About .description h2 {
    font-size: 24px !important;
    line-height: 1; }
  .About .description > * {
    min-width: 300px;
    max-width: 500px;
    margin: 0 25px;
    margin-bottom: 50px; }
  .About .description ul {
    list-style-type: none; }
    .About .description ul li {
      position: relative; }
      .About .description ul li:before {
        content: "\2713";
        position: absolute;
        left: -20px; }
  .About .description a {
    color: inherit;
    text-decoration: none;
    background-color: orange;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block; }
    .About .description a:hover {
      -webkit-animation: vibrate-1 0.3s linear infinite both;
              animation: vibrate-1 0.3s linear infinite both; }
    .About .description a:visited {
      color: inherit; }
  .About .description .site-footer hr {
    margin: 12px 0;
    margin-right: -500px; }
    @media (max-width: 400px) {
      .About .description .site-footer hr {
        margin-right: 0; } }
  .About .description .site-footer .links, .About .description .site-footer .byline {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .About .description .site-footer .links a, .About .description .site-footer .byline a {
      margin-right: 12px; }
      .About .description .site-footer .links a:last-child, .About .description .site-footer .byline a:last-child {
        margin: 0; }
  .About .description .site-footer .personal a {
    background-color: springgreen; }
  .About .description .spiel {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.App, #root, body, html {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible; }
  @media (max-width: 400px) {
    .App, #root, body, html {
      overflow: unset; } }

@media (max-width: 400px) {
  .App {
    padding-top: 35vw; }
    .App header {
      width: 100%;
      top: 0;
      position: fixed; }
    .App nav {
      position: fixed;
      top: 25vw; } }

header {
  display: block;
  text-align: center;
  z-index: 2;
  flex-shrink: 0;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(-8deg);
          transform: rotate(-8deg);
  position: relative; }
  header h2 {
    position: absolute;
    bottom: 0;
    font-size: 3vw;
    font-weight: 700;
    font-style: italic;
    background-color: white;
    border: 1.5vw double black;
    right: -100px;
    left: -100px; }
  header h1 {
    font-family: Monoton, sans-serif;
    font-weight: normal;
    font-size: 15vw;
    background-color: springgreen;
    color: green;
    border: 1.5vw double black;
    border-radius: 10px;
    margin: 0;
    width: 80%; }

nav {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-end;
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
  -webkit-transform-origin: top right;
          transform-origin: top right;
  background-color: orange;
  width: 100%;
  margin-left: -50%;
  padding: 20px 45%;
  flex-wrap: wrap;
  border: 1.5vw double black;
  border-radius: 10px;
  z-index: 1;
  margin-bottom: -50px; }
  nav a {
    background-color: white;
    cursor: pointer;
    display: block;
    text-decoration: none;
    border: 6px double black;
    padding: 10px 30px;
    border-radius: 5px;
    margin-left: 2.5vw;
    color: black;
    font-weight: bold; }
    nav a .icon {
      display: none; }
    nav a.active {
      border-color: orange;
      color: orange;
      background-color: black; }
    nav a:hover:not(.active) {
      -webkit-animation: vibrate-1 0.3s linear infinite both;
              animation: vibrate-1 0.3s linear infinite both; }
  @media (max-width: 400px) {
    nav {
      padding-top: 5px;
      padding-bottom: 5px; }
      nav a {
        font-size: 25px;
        padding: 5px 10px; }
        nav a.about {
          display: none; }
        nav a .icon {
          display: inline; }
        nav a .text {
          display: none; } }

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  20% {
    -webkit-transform: translate(-2px, 2px) rotate(2deg);
            transform: translate(-2px, 2px) rotate(2deg); }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
    background-color: orange;
    color: white; }
  60% {
    -webkit-transform: translate(2px, 2px) rotate(-2deg);
            transform: translate(2px, 2px) rotate(-2deg); }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  20% {
    -webkit-transform: translate(-2px, 2px) rotate(2deg);
            transform: translate(-2px, 2px) rotate(2deg); }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
    background-color: orange;
    color: white; }
  60% {
    -webkit-transform: translate(2px, 2px) rotate(-2deg);
            transform: translate(2px, 2px) rotate(-2deg); }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

.Shucks {
  display: inline-flex;
  align-items: center;
  font-size: 25px; }
  .Shucks.native, .Shucks.native .shuck-symbol {
    font-size: inherit; }
  .Shucks .shuck-symbol {
    font-size: 50px;
    font-family: Monoton, sans-serif; }

.Account {
  display: flex;
  flex: 1 1;
  flex-direction: row; }
  .Account .sidebar {
    z-index: 0;
    min-width: 400px;
    position: relative;
    margin-right: 50px;
    color: orange;
    display: flex;
    flex-direction: column; }
    .Account .sidebar .balance {
      padding: 0 20px;
      margin-top: -10%; }
      .Account .sidebar .balance .shucks {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .Account .sidebar .balance .shucks .econ {
          text-align: right; }
    .Account .sidebar .transactions {
      overflow-y: auto;
      overflow-x: visible;
      flex: 1 1;
      flex-basis: 0; }
      .Account .sidebar .transactions .icon {
        display: none; }
      .Account .sidebar .transactions h3 {
        padding: 0 20px; }
      .Account .sidebar .transactions table {
        width: 100%;
        border-collapse: collapse; }
        .Account .sidebar .transactions table td {
          padding: 0 20px;
          border-bottom: 3px solid black; }
        .Account .sidebar .transactions table tr:nth-child(2n) {
          background: orange;
          color: purple; }
    .Account .sidebar:before {
      content: "";
      border-right: 15px double black;
      background-color: purple;
      position: absolute;
      top: -1000px;
      right: -15px;
      bottom: 0;
      z-index: -1;
      left: 0; }
  .Account .About {
    overflow: auto; }
  .Account .info {
    margin-top: -3%;
    flex: 1 1;
    display: flex;
    flex-direction: column; }
    .Account .info > * {
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1 1;
      flex-direction: column;
      justify-content: space-between; }
      .Account .info > * > * {
        padding: 20px; }
    .Account .info h2 {
      font-size: 100px;
      margin: 0; }
  @media (max-width: 400px) {
    .Account {
      flex-direction: column;
      width: 100%; }
      .Account .sidebar {
        -webkit-transform: rotate(-1deg);
                transform: rotate(-1deg);
        position: fixed;
        top: 30vw;
        height: auto;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        height: 70px;
        margin: 0;
        min-width: unset;
        outline: 5px solid black;
        background-color: purple;
        padding-top: 50px; }
        .Account .sidebar:before {
          display: none; }
        .Account .sidebar h3 {
          display: none; }
        .Account .sidebar .transactions {
          overflow: unset; }
          .Account .sidebar .transactions table td:nth-child(2) {
            padding: 0; }
          .Account .sidebar .transactions .icon {
            display: inline; }
          .Account .sidebar .transactions .text {
            display: none; }
      .Account .info {
        margin-top: 100px; }
        .Account .info > * {
          overflow: unset; }
        .Account .info h2 {
          margin: 0;
          font-size: 30px;
          background-color: black;
          color: orange; }
        .Account .info input {
          display: block;
          width: 100%; } }

.Burn {
  position: relative;
  color: white; }
  .Burn input {
    color: inherit; }
  .Burn .fire {
    position: fixed;
    z-index: -5;
    bottom: 0;
    right: 0;
    background-color: black;
    height: 100%;
    width: calc(100% - 400px); }
  @media (max-width: 400px) {
    .Burn .fire {
      width: 100%;
      bottom: -30%; } }

